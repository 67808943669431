import React, {useEffect, useRef} from "react";

import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

const Fancybox = React.forwardRef((props, ref) => {
    const delegate = props.delegate || "[data-fancybox]";
    const fancyboxInstance = useRef();

    const stylesheet = {
        // flex flex-wrap gap-5 justify-center max-w-5xl mx-auto px-6
        docsGallery: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "1rem",
            position: "relative",
            top: "6.7807rem",
            zIndex: "100",
            background: "rgba(0,0,0,0.5)",
            width: "80vw",
            margin: "-5rem auto 0 auto",
            paddingBottom: "0.75rem",
            opacity: "0",
            pointerEvents: "none",
        }
    }

    useEffect(() => {
        const opts = props.options || {};

        NativeFancybox.bind(delegate, {
            on: {
                load: (fancybox, slide) => {
                    fancyboxInstance.current = NativeFancybox.getInstance(null);
                },
            },
            ...opts
        });

        return () => {
            NativeFancybox.destroy();
        };
    }, [delegate, props.options]);

    return <div style={stylesheet.docsGallery} ref={ref}>{props.children}</div>;
});

export default Fancybox;