import MissionConsole from './components/MissionControl/MissionConsole';
import './App.css';

function App() {
  return (
    <div className="App" style={styleSheet.app}>
      <MissionConsole/>
    </div>
  );
}

export default App;

const styleSheet = {
    app: {
        background: "#000"
    }
};