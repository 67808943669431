import React from "react";
import { MissionConsoleContext } from "../../contexts/MissionConsoleContext";

const styleSheet = {
    hiddenInput: {
        position: "absolute",
        left: "-100rem",
    },
    visibleInput: {
        wordWrap: "break-word",
    }
};
export default class ConsoleInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showingInput: true };
        this.focusInput = this.focusInput.bind(this);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this.context.ConsoleProps.addListener('screenClickEvent', 'screenClick', () => this.focusInput());
    }

    componentWillUnmount() {
        this.context.ConsoleProps.delListener('screenClickEvent');
    }

    focusInput() {
        const setter = this.myRef.current.querySelector("#setter");
        setter.focus();
    }

    async onKeyUp(e) {
        if (e.key === "Enter") {
            await this.submitInput();
        }
    }

    async submitInput() {
        const setter = this.myRef.current.querySelector("#setter");
        const writer = this.myRef.current.querySelector("#writer");

        const outputMsg = await this.props.onSubmit(setter.value);

        if (outputMsg !== "") {
            writer.innerHTML = `>&nbsp;` + outputMsg + `<strong class="cursor" id="cursor">&nbsp;</strong>`;

            setTimeout(() => {
                setter.value = "";
                writer.innerHTML = `>&nbsp;<strong class="cursor" id="cursor">&nbsp;</strong>`;
            }, 2000);
        }
        else {
            setter.value = "";
            writer.innerHTML = `>&nbsp;<strong class="cursor" id="cursor">&nbsp;</strong>`;
        }
    }

    writeIt(e) {
        //console.log(e);
        if (this.state.showingInput === false) return;

        const writer = this.myRef.current.querySelector("#writer");
        const setter = this.myRef.current.querySelector("#setter");

        let filteredText = this.props.pattern(e.target.value);

        writer.innerHTML = `>&nbsp;` + filteredText + `<strong class="cursor" id="cursor">&nbsp;</strong>`;
        setter.value = filteredText;
    }


    render() {
        return(
            <div ref={this.myRef}>
                <input type={"text"} id={"setter"} className={"faketerminal"} style={styleSheet.hiddenInput} onInput={(e) => this.writeIt(e)} onKeyUp={(e) => this.onKeyUp(e)}/>
                <div id="getter">
                    <p id="writer" style={styleSheet.visibleInput}>>&nbsp;<strong className="cursor" id="cursor">&nbsp;</strong></p>
                </div>
            </div>
        )
    }
}

ConsoleInput.contextType = MissionConsoleContext;