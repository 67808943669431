import React from "react";
import { EventEmitter } from "fbemitter";
import { io } from "socket.io-client";
import { Utils } from "../../utils/Util";

import { MissionConsoleContext } from '../../contexts/MissionConsoleContext';

import Screen from './Screen';
import ConsoleButtons from './ConsoleButtons';
import missionConsole from '../../resources/mission_console.png';
import Popup from "./Popup";
import Fancybox from "../Fancybox";

import * as DocThumbs from "../../resources/apollo_docs/thumbs/index";
import * as DocOriginals from "../../resources/apollo_docs/originals/index";
import SuccessVideoMP4 from "../../resources/success_video.mp4";
import SuccessVideoWEBM from "../../resources/success_video.webm";
import RadioScratch from "../../resources/RadioScratch.mp3";
import RadioBeep from "../../resources/nasaBeep.mp3";

const consoleEmitter = new EventEmitter();
const consoleListeners = new Map();
const intervalMap = new Map();
const socket = io("https://goddard.apollogame.site/");

let lostGoddardTransmission = false;

const startup = () => {

    socket.on("connect", () => {
        const consoleName = Utils.getConsoleName();

        socket.emit("message", {
            type: "consoleGreeting",
            content: {
                consoleName: consoleName
            }
        }, (response) => {
            //console.log(response.status); // ok
        });
    });

    socket.on("consoleMessage", (message) => {
        const { code } = message;

        if(code === "landing-initiated")
        {
            consoleEmitter.emit("landing-initiated");
        }
        else if(code === "disaster-initiated")
        {
            consoleEmitter.emit("disaster-initiated");
        }
        else if(code === "lemStatus")
        {
            const { altitudeRate, landingTimerRate, altitudeValue, landingTimerValue } = message.content;

            consoleEmitter.emit("lemUpdate", message.content);
        }
        else if(code === "mission-win") {
            consoleEmitter.emit("missionWin");
        }
        else if(code === "mission-fail") {
            consoleEmitter.emit("missionFail");
        }
    });

    socket.on("disconnect", () => {
        consoleEmitter.emit("goddard-lost");
    });

    socket.on("error", (error) => {
        if(lostGoddardTransmission === false) {
            consoleEmitter.emit("goddard-lost");
            lostGoddardTransmission = true;
        }
    });

    socket.on("reconnect", (attempt) => {
        lostGoddardTransmission = false;
        consoleEmitter.emit("goddard-found");
    });

    socket.on("reconnect_failed", () => {
    });
};

startup();

export default class MissionConsole extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showVideo: false,
            consoleInfo: {
                consoleName: Utils.getConsoleName(),
                playerCode: Utils.getConsoleName().substr(0,4).toUpperCase(),
                isLinkedLM: false,
            },
            shipInfo: {
                shipNickname: "",
            },
        }

        this.docGalleryNode = React.createRef();

        this.ConsoleProps = {
            doEmit: this.doEmit.bind(this),
            addListener: this.addListener.bind(this),
            delListener: this.delListener.bind(this),
            showGallery: this.showDocGallery.bind(this),
            intervalMap: intervalMap,
            socket: socket,
            consoleName: Utils.getConsoleName(),
            consoleRole: "null",
            playerCode: Utils.getConsoleName().substr(0,4).toUpperCase(),
            isLinkedLM: false,
            shipNickname: "",
            missionState: {
                didAskToBypass: false,
                isMissingLEM: false,
                isMissingCapcom: false,
                isBypassingEngineers: false,
                isGroupComplete: true,
            },
            lemStatus: {
                isLanding: false,
                didDisasterStart: false,
                altitudeRate: null,
                altitudeValue: null,
                landingTimerRate: null,
                landingTimerValue: 480,
                asphyxiationTimerRate: null,
                asphyxiationTimerValue: 500,
                oxygenLevel: 99, // starts at 99% and once disaster starts, lowers to 95% over the timer's course
                co2Level: 1, // starts at 1% and once disaster is triggered, raise to 5% over the landingTimer's course
                mainFuel: 100, // starts at 100, decreases when landing starts; ends at 8%
                interiorTemp: 70, // starts at 70, randomly increments/decrements by 1 degree every 2 minutes

                isPaused: false,
            }
        }
    }

    socketOnGoddardMessage = (message) => {
        const { type } = message;

        if(type === "lem-linked")
        {
            consoleEmitter.emit("lemLinked");
        }
        else if(type === "group-status-change") {
            consoleEmitter.emit("popup-onChangeFrame", "GroupChange", message.content);
        }
        else if(type === "transmission-restore") {
            const {content} = message;

            this.ConsoleProps.consoleRole = content.clientState.role;
            this.ConsoleProps.isLinkedLM = content.groupState.missionState.isLinkedLEM;
            this.ConsoleProps.shipNickname = content.groupState.missionState.LEMnickname;
            this.ConsoleProps.missionState.isMissingLEM = (this.ConsoleProps.isLinkedLM && content.groupState.numActiveLEMs !== 1);
            this.ConsoleProps.missionState.isMissingCapcom = (content.groupState.numActiveCapcoms !== 1);
            this.ConsoleProps.missionState.isGroupComplete = (content.groupState.isInactive === 0);
            this.ConsoleProps.lemStatus.isLanding = content.groupState.missionState.isLanding;
            this.ConsoleProps.lemStatus.didDisasterStart = content.groupState.missionState.didDisasterStart;
            this.ConsoleProps.lemStatus.isPaused = content.groupState.missionState.isPaused;

            consoleEmitter.emit("popup-onRestore", content.clientState.popupHistory);
            consoleEmitter.emit("screen-onRestore", content.clientState.screenHistory);
        }
    }

    addListener(tokenName, eventName, callback) {
        consoleListeners.set(tokenName, consoleEmitter.addListener(eventName, callback));
    }

    delListener(tokenName) {
        let token = consoleListeners.get(tokenName);
        if (token === undefined) return;
        token.remove();

        consoleListeners.delete(tokenName);
    }

    doEmit(eventName, ...args) {
        consoleEmitter.emit(eventName, ...args);
    }

    clearLandingIntervals() {
        if(this.ConsoleProps.intervalMap.has("interiorTempTick")) {
            clearInterval(this.ConsoleProps.intervalMap.get("interiorTempTick"));
        }

        if(!this.ConsoleProps.lemStatus.isLanding) {
            return;
        }

        if(this.ConsoleProps.intervalMap.has("landingTimerTick")) {
            clearInterval(this.ConsoleProps.intervalMap.get("landingTimerTick"));
        }
        if(this.ConsoleProps.intervalMap.has("altitudeTick")) {
            clearInterval(this.ConsoleProps.intervalMap.get("altitudeTick"));
        }
        if(this.ConsoleProps.intervalMap.has("mainFuelTick")) {
            clearInterval(this.ConsoleProps.intervalMap.get("mainFuelTick"));
        }
    }

    setLandingIntervals() {
        // clear landing-related intervals first before re-triggering, in-case we record over an interval entry, never to be accessed again
        this.clearLandingIntervals();

        // randomly increment/decrement by 1 every 2 minutes
        this.ConsoleProps.intervalMap.set("interiorTempTick", setInterval(() => {
            const rand = Math.floor(Math.random() * 10);
            if(rand > 4) {
                this.ConsoleProps.lemStatus.interiorTemp += 1;
            }
            else {
                this.ConsoleProps.lemStatus.interiorTemp -= 1;
            }
        }, 120000));

        if(!this.ConsoleProps.lemStatus.isLanding) {
            return;
        }

        // decrease landingTimerValue by 0.1 units per 0.1 seconds
        this.ConsoleProps.intervalMap.set("landingTimerTick", setInterval(() => {
            this.ConsoleProps.lemStatus.landingTimerValue -= 0.1;
            if(this.ConsoleProps.lemStatus.landingTimerValue <= 0) {
                this.ConsoleProps.lemStatus.landingTimerValue = 0;
                clearInterval(this.ConsoleProps.intervalMap.get("landingTimerTick"));
            }
        }, 100));

        // decrease altitudeValue by altitudeRate units per second
        this.ConsoleProps.intervalMap.set("altitudeTick", setInterval(() => {
            this.ConsoleProps.lemStatus.altitudeValue -= this.ConsoleProps.lemStatus.altitudeRate;
            if(this.ConsoleProps.lemStatus.altitudeValue <= 0) {
                this.ConsoleProps.lemStatus.altitudeValue = 0;
                clearInterval(this.ConsoleProps.intervalMap.get("altitudeTick"));
            }
        }, 1000));

        // decrease landingTimerValue by ~0.19 units per second
        // TODO: For flexibility, consider getting the countdownDuration of the landingTimer to calculate this dynamically
        this.ConsoleProps.intervalMap.set("mainFuelTick", setInterval(() => {
            this.ConsoleProps.lemStatus.mainFuel -= 0.1916666666666667;
            if(this.ConsoleProps.lemStatus.mainFuel <= 8) {
                this.ConsoleProps.lemStatus.mainFuel = 8;
                clearInterval(this.ConsoleProps.intervalMap.get("mainFuelTick"));
            }
        }, 1000));
    }

    clearDisasterIntervals() {
        if(!this.ConsoleProps.lemStatus.didDisasterStart) return;

        if(this.ConsoleProps.intervalMap.has("oxygenLevelTick")) {
            clearInterval(this.ConsoleProps.intervalMap.get("oxygenLevelTick"));
        }
        if(this.ConsoleProps.intervalMap.has("co2LevelTick")) {
            clearInterval(this.ConsoleProps.intervalMap.get("co2LevelTick"));
        }
    }

    setDisasterIntervals() {
        if(!this.ConsoleProps.lemStatus.didDisasterStart) {
            return;
        }

        // clear disaster-related intervals first before re-triggering, in-case we record over an interval entry, never to be accessed again
        this.clearDisasterIntervals();

        // decrease oxygenLevel by 0.48 units per minute
        // TODO: For flexibility, consider getting the countdownDuration of the disasterTimer to calculate this dynamically
        this.ConsoleProps.intervalMap.set("oxygenLevelTick", setInterval(() => {
            this.ConsoleProps.lemStatus.oxygenLevel -= 0.48;
            if(this.ConsoleProps.lemStatus.oxygenLevel <= 95) {
                this.ConsoleProps.lemStatus.oxygenLevel = 95;
                clearInterval(this.ConsoleProps.intervalMap.get("oxygenLevelTick"));
            }
        }, 60000));

        // increase co2Level by 0.48 units per minute
        // TODO: For flexibility, consider getting the countdownDuration of the disasterTimer to calculate this dynamically
        this.ConsoleProps.intervalMap.set("co2LevelTick", setInterval(() => {
            this.ConsoleProps.lemStatus.co2Level += 0.48;
            if(this.ConsoleProps.lemStatus.co2Level >= 5) {
                this.ConsoleProps.lemStatus.co2Level = 5;
                clearInterval(this.ConsoleProps.intervalMap.get("co2LevelTick"));
            }
        }, 60000));
    }

    doPause() {
        this.ConsoleProps.lemStatus.isPaused = true;

        this.clearLandingIntervals();

        this.clearDisasterIntervals();
    }

    doUnpause() {
        this.ConsoleProps.lemStatus.isPaused = false;

        this.setLandingIntervals();

        this.setDisasterIntervals();
    }

    showDocGallery() {
        this.docGalleryNode.current.style.opacity = "1";
        this.docGalleryNode.current.style.pointerEvents = "initial";

        this.ConsoleProps.socket.emit("message", {
            type: "stateUpdate",
            content: {
                isUsingDigitalDocs: true
            }
        }, (response) => {
        });
    }

    showVideo() {
        this.setState({
           showVideo: true,
        });
    }

    componentDidMount() {
        socket.on("goddardMessage", this.socketOnGoddardMessage);

        document.addEventListener("keydown", (e) => {
            if(e.code === "Enter" && !e.repeat) {
                let radioScratchSFX = new Audio(RadioScratch);
                radioScratchSFX.play();
            }
        });

        document.addEventListener("keyup", (e) => {
            if(e.code === "Enter" && !e.repeat) {
                let radioBeepSFX = new Audio(RadioBeep);
                radioBeepSFX.play();
            }
        });

        this.addListener('mc-landingInit', 'landing-initiated', () => {
            this.ConsoleProps.lemStatus.isLanding = true;
        });

        this.addListener('mc-disasterInit', 'disaster-initiated', () => {
            this.ConsoleProps.lemStatus.didDisasterStart = true;
        });

        this.addListener('mc-missionWin', 'missionWin', () => {
            consoleEmitter.emit("popup-onChangeFrame", "MissionWin");
            setTimeout(() => {
                this.showVideo();
            }, 15000);
        });

        this.addListener('mc-missionFail', 'missionFail', () => {
            consoleEmitter.emit("popup-onChangeFrame", "MissionFail");
        });

        this.addListener('mc-lemUpdate', 'lemUpdate', (statusUpdate) => {

            if(statusUpdate.hasOwnProperty("altitudeRate") && statusUpdate.altitudeRate !== 0) {
                this.ConsoleProps.lemStatus.altitudeRate = statusUpdate.altitudeRate;
            }
            if(statusUpdate.hasOwnProperty("landingTimerRate") && statusUpdate.landingTimerRate !== 0) {
                this.ConsoleProps.lemStatus.landingTimerRate = statusUpdate.landingTimerRate;
            }
            if(statusUpdate.hasOwnProperty("asphyxiationTimerRate") && statusUpdate.asphyxiationTimerRate !== 0) {
                this.ConsoleProps.lemStatus.asphyxiationTimerRate = statusUpdate.asphyxiationTimerRate;
            }

            for (const key in statusUpdate) {
                if(statusUpdate[key] == null || statusUpdate[key] === 0) continue;

                if(key === "landingTimerValue" || key === "altitudeValue") {
                    this.ConsoleProps.lemStatus[key] = statusUpdate[key];
                    this.setLandingIntervals();
                }
                else if(key === "asphyxiationTimerValue") {
                    this.ConsoleProps.lemStatus[key] = statusUpdate[key];
                    this.setDisasterIntervals();
                }
                else if(key === "isPaused") {
                    const isPaused = statusUpdate["isPaused"];
                    const { lemStatus } = this.ConsoleProps;

                    if(isPaused && lemStatus.isPaused === false) {
                        this.doPause();
                        this.doEmit("lem-onPause");

                        // open popup screen to tell mission console user that astronaut paused game
                        this.doEmit("popup-onChangeFrame", "PauseScreen");
                    }
                    else if(!isPaused && lemStatus.isPaused === true) {
                        this.doUnpause();
                        this.doEmit("lem-onUnpause");

                        // close popup pause screen
                        this.doEmit("popup-onGoBack");
                    }
                }
                else {
                    if (this.ConsoleProps.lemStatus.hasOwnProperty(key)) {
                        this.ConsoleProps.lemStatus[key] = statusUpdate[key];
                    }
                }
            }
        });
    }

    componentWillUnmount() {
        socket.off("goddardMessage", this.socketOnGoddardMessage);
        this.delListener('mc-lemUpdate');
        this.delListener('mc-landingInit');
        this.delListener('mc-disasterInit');

        document.removeEventListener("keydown", (e) => {
            if(e.code === "Enter" && !e.repeat) {
                let radioScratchSFX = new Audio(RadioScratch);
                radioScratchSFX.play();
            }
        });

        document.removeEventListener("keyup", (e) => {
            if(e.code === "Enter" && !e.repeat) {
                let radioBeepSFX = new Audio(RadioBeep);
                radioBeepSFX.play();
            }
        });
    }

    render() {
        return(
            <>
                <MissionConsoleContext.Provider value={{ ConsoleProps: this.ConsoleProps, MCState: this.state }}>
                    {this.state.showVideo === false &&
                        <Fancybox ref={this.docGalleryNode} options={{
                            infinite: false,
                            preload: 8,
                            Thumbs: {
                                autoStart: true,
                            },
                            Toolbar: {
                                display: [
                                    {id: "prev", position: "center"},
                                    {id: "counter", position: "center"},
                                    {id: "next", position: "center"},
                                    "close",
                                ],
                            }
                        }}>
                            <a data-fancybox="gallery" href={DocOriginals.Doc1} className="doc-thumb">
                                <img alt="" className="rounded" src={DocThumbs.DocThumb1}/>
                            </a>
                            <a data-fancybox="gallery" href={DocOriginals.Doc2} className="doc-thumb">
                                <img alt="" className="rounded" src={DocThumbs.DocThumb2}/>
                            </a>
                            <a data-fancybox="gallery" href={DocOriginals.Doc3} className="doc-thumb">
                                <img alt="" className="rounded" src={DocThumbs.DocThumb3}/>
                            </a>
                            <a data-fancybox="gallery" href={DocOriginals.Doc4} className="doc-thumb">
                                <img alt="" className="rounded" src={DocThumbs.DocThumb4}/>
                            </a>
                            <a data-fancybox="gallery" href={DocOriginals.Doc5} className="doc-thumb">
                                <img alt="" className="rounded" src={DocThumbs.DocThumb5}/>
                            </a>
                            <a data-fancybox="gallery" href={DocOriginals.Doc6} className="doc-thumb">
                                <img alt="" className="rounded" src={DocThumbs.DocThumb6}/>
                            </a>
                            <a data-fancybox="gallery" href={DocOriginals.Doc7} className="doc-thumb">
                                <img alt="" className="rounded" src={DocThumbs.DocThumb7}/>
                            </a>
                            <a data-fancybox="gallery" href={DocOriginals.Doc8} className="doc-thumb">
                                <img alt="" className="rounded" src={DocThumbs.DocThumb8}/>
                            </a>
                        </Fancybox>
                    }
                    <div className={"mc-container"} style={styleSheet.missionConsoleContainer}>
                        {this.state.showVideo === false ?
                            <>
                                <div className={"mc-ui"} style={styleSheet.missionConsoleUI}/>
                                <Screen style={screenStyle}/>
                                <ConsoleButtons style={buttonsStyle}/>
                                <Popup style={popupStyle}/>
                            </>
                            :
                            <>
                                <video autoPlay="true" disablePictureInPicture="true" style={styleSheet.videoFullscreen}>
                                    <source src={SuccessVideoWEBM} type="video/webm"/>
                                    <source src={SuccessVideoMP4} type="video/mp4"/>
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                            </>
                        }
                    </div>
                </MissionConsoleContext.Provider>
            </>
        )
    }
}

const styleSheet = {
    missionConsoleContainer: {
        position: "relative",
        maxWidth: "80vw",
        margin: "2rem auto 4rem auto",
        height: "0",
        paddingTop: "45%"
    },
    missionConsoleUI: {
        position: "absolute",
        top: "0",
        width: "100%",
        backgroundImage: `url("${missionConsole}")`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        paddingTop: "56.25%"
    },
    videoFullscreen: {
        position: "absolute",
        top: "0",
        width: "100%",
        display: "block",
    }
};

const screenStyle = {
    screen: {
        position: "absolute",
        top: "14.9%",
        left: "17.3%",
        width: "38.1vw",
        height: "58.5%",
        padding: "1rem",

        backgroundColor: "black",
        backgroundImage: "radial-gradient(rgba(0, 150, 0, 0.35), black 120%)",

        fontFamily: "Inconsolata, monospace",
        fontWeight: "600",
        fontSize: "calc(100vw/96)",
        color: "#0f0"
    },
    text: {
        textShadow: "0px 0px 12px rgba(0, 255, 0, 0.6),0px -5px 19px rgba(0, 255, 0, 0.4),0px 0px 4px rgba(168, 255, 128, 0.3),0px 5px 19px rgba(0, 255, 0, 0.2),-5px 0px 19px rgba(101, 80, 57, 0.2),5px 0px 19px rgba(98, 37, 104, 0.2)",
        textAlign: "left"
    }
}

const buttonsStyle = {
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "0 auto",
        position: "absolute",
        bottom: "calc(80vw/-160)",
        width: "71%",
        left: "9%",
        height: "calc(80vw/14)",
        alignItems: "flex-end",
    },
    btns: {
        position: "relative",
        backgroundColor: "transparent",
        border: "0",
        outline: "0",
        padding: "0",
        cursor: "pointer",
        width: "calc(80vw/12)",
        height: "calc(80vw/16)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
    },
    btnText: {
        position: "absolute",
        zIndex: "1",
        top: "42%",
        right: "51%",
        transform: "translate(50%,-50%)",
        margin: "0",

        fontSize: "calc(80vw/90)",
        fontWeight: "800",
        color: "rgba(0,0,0,0.75)",
        fontFamily: "Kanit",
        lineHeight: "calc(80vw/90)",

        userSelect: "none"
    }
}

const popupStyle = {
    curtain: {
        position: "absolute",
        zIndex: "5",
        top: "0",
        left: 0,
        right: 0,
        bottom: "-0.45rem",
        background: "rgba(255,255,255,0.3)",
        backdropFilter: "blur(12px)",
    },
    window: {
        position: "absolute",
        width: "75%",
        height: "60%",
        top: "50%",
        right: "50%",
        transform: "translate(50%,-50%)",
        background: "rgba(4, 4, 4, 0.9)",
        boxShadow: "0px 10px 25px 1px rgba(0,0,0,0.6)",
        color: "#fff",
    },
    windowFrame: {
        position: "absolute",
        top: "50%",
        right: "50%",
        width: "100%",
        height: "fit-content",
        padding: "0 calc(80vw/40)",
        transform: "translate(50%,-50%)",
    },
    header: {
        fontFamily: 'Futuracrunch',
        marginTop: "0",
        fontSize: "calc(80vw/18)",
        textShadow: "0px 2px 8px rgba(0, 0, 0, 0.8)",
    },
    body: {
        fontFamily: 'FuturacrunchCond',
        fontSize: "calc(80vw/34)",
    }
}