import React from "react";
import {MissionConsoleContext} from "../../contexts/MissionConsoleContext";

export default class ConsoleButtons extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            capcomBtn: false,
            statusBtn: false,
            landingBtn: false,
            goBtn: false,
            panicBtn: false,
        }
    }

    componentDidMount() {
        this.context.ConsoleProps.addListener('cb-UpdateState', 'cbUpdateState', (newState) => this.setState(newState));
    }

    componentWillUnmount() {
        this.context.ConsoleProps.delListener('cb-UpdateState');
    }

    render() {
        return(
            <div ref={this.myRef} className={"mc-btns"} style={this.props.style.container}>
                <button style={this.props.style.btns} onClick={() => this.context.ConsoleProps.doEmit('capcomBtnClick')} disabled={!this.state.capcomBtn} id={"capcomBtn"}>
                    <p style={this.props.style.btnText}>CAPCOM CHANNEL</p>
                </button>
                <button style={this.props.style.btns} onClick={() => this.context.ConsoleProps.doEmit('statusBtnClick')} disabled={!this.state.statusBtn} id={"statusBtn"}>
                    <p style={this.props.style.btnText}>STATUS CHANNEL</p>
                </button>
                <button style={this.props.style.btns} onClick={() => this.context.ConsoleProps.doEmit('landingBtnClick')} disabled={!this.state.landingBtn} id={"landingBtn"}>
                    <p style={this.props.style.btnText}>LANDING CLEAR</p>
                </button>
                <button style={this.props.style.btns} onClick={() => this.context.ConsoleProps.doEmit('goBtnClick')} disabled={!this.state.goBtn} id={"goBtn"}>
                    <p style={this.props.style.btnText}>GO</p>
                </button>
                <button style={this.props.style.btns} onClick={() => this.context.ConsoleProps.doEmit('panicBtnClick')} disabled={!this.state.panicBtn} id={"panicBtn"}>
                    <p style={this.props.style.btnText}>PANIC</p>
                </button>
            </div>
        )
    }
}

ConsoleButtons.contextType = MissionConsoleContext;