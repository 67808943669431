import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import { MissionConsoleContext } from '../../contexts/MissionConsoleContext';
import ConsoleInput from './ConsoleInput';
import { Utils } from '../../utils/Util';
import Stack from "../../utils/Stack";

function StartScreen(props) {
    return (
        <>
        <div style={props.style.text}>
            <h1>Console Initializing...</h1>
        </div>
    </>
    );
}

class P5A extends React.Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }
    consoleInputPattern(value) {
        const reg = /[^0-9_-]/g;
        value = value.replace(reg, "");

        if(value.length >= 5) {
            value = value.substring(0, 5);
        }

        return value;
    };

    async consoleSubmit(submission) {
        let outputMsg = "";
        const isReady = Utils.defer();

        this.context.ConsoleProps.socket.emit("message", {
            type: "lemLink",
            content: {
                altitude: submission,
                nickname: this.context.ConsoleProps.shipNickname,
            }
        }, (response) => {
            outputMsg = response.msg;
            if (response.status === "ok") {
                this.context.ConsoleProps.isLinkedLM = true;
                this.context.ConsoleProps.doEmit('cbUpdateState', { goBtn:true });
                this.context.ConsoleProps.addListener('p5a-go', 'goBtnClick', () => this.props.changeFrame('5B'));
                this.forceUpdate();
            }
            isReady.resolve();
        });

        await isReady;
        return outputMsg;
    };

    componentDidMount() {
        this.context.ConsoleProps.socket.emit("message", {
            type: "stateUpdate",
            content: {
                name: this.context.ConsoleProps.consoleName,
                state: "lem-linking"
            }
        }, (response) => {
            // TODO: do proper error-checking?
        });

        this.context.ConsoleProps.doEmit('cbUpdateState', { goBtn:true });

        if(this.context.ConsoleProps.isLinkedLM) {
            this.context.ConsoleProps.addListener('p5a-go', 'goBtnClick', () => this.props.changeFrame('5B'));
        } else {
            this.context.ConsoleProps.addListener('p5a-submit', 'goBtnClick', () => {
                if(this.child != null)
                    this.child.submitInput();
            });
        }
    }

    componentWillUnmount() {
        if (this.context.ConsoleProps.isLinkedLM) {
            this.context.ConsoleProps.delListener('p5a-go');
        } else {
            this.context.ConsoleProps.delListener('p5a-submit');
        }
        this.context.ConsoleProps.doEmit('cbUpdateState', { goBtn:false });
    }

    render() {
        return <>
            <div style={this.props.style.text}>
                <h1>CAPCOM - System Calibration</h1>
                {this.context.ConsoleProps.isLinkedLM
                    ? <>
                        <p>We are currently<strong>{this.context.ConsoleProps.isLinkedLM ? ' ' : ' not '}</strong>connected to {this.context.ConsoleProps.shipNickname}</p>
                        <p>Press GO</p>
                      </>
                    : <>
                        <p>- Request exact lunar altitude from <span>{this.context.ConsoleProps.shipNickname}</span></p>
                        <p>Enter exact lunar altitude...</p>
                        <ConsoleInput ref={Ref => this.child=Ref } {... this.props} pattern={this.consoleInputPattern} onSubmit={(submission) => this.consoleSubmit(submission)} />
                      </>
                }
            </div>
        </>;
    }
}
P5A.contextType = MissionConsoleContext;

class P5B extends React.Component {
    componentDidMount() {
        this.context.ConsoleProps.doEmit('cbUpdateState', { goBtn:true });
        this.context.ConsoleProps.addListener('p5b-go', 'goBtnClick', () => this.props.changeFrame('5B2'));

        this.context.ConsoleProps.socket.emit("message", {
            type: "stateUpdate",
            content: {
                name: this.context.ConsoleProps.consoleName,
                state: "skills-test"
            }
        }, (response) => {
            // TODO: do proper error-checking?
        });
    }

    componentWillUnmount() {
        this.context.ConsoleProps.doEmit('cbUpdateState', { goBtn:false });
        this.context.ConsoleProps.delListener('p5b-go');
    }

    render() {
        return <>
            <div style={this.props.style.text}>
                <h1>CAPCOM - Motor Skills Test</h1>
                <p>- System Synchronized...</p>
                <p>Ask <span>{this.context.ConsoleProps.shipNickname}</span> to:</p>
                <p>- Left Click to grab up to 2 objects</p>
                <p>- Right Click to let go</p>
                <p>Press GO upon completion.</p>
            </div>
        </>;
    }
}
P5B.contextType = MissionConsoleContext;

class P5B2 extends React.Component {

    componentDidMount() {
        this.context.ConsoleProps.doEmit('cbUpdateState', { goBtn:true });
        this.context.ConsoleProps.addListener('p5b2-go', 'goBtnClick', () => this.props.changeFrame('5C'));
    }

    componentWillUnmount() {
        this.context.ConsoleProps.doEmit('cbUpdateState', { goBtn:false });
        this.context.ConsoleProps.delListener('p5b2-go');
    }

    render() {
        return <>
            <div style={this.props.style.text}>
                <h1>CAPCOM - Motor Skills Test</h1>
                <p>Inform <span>{this.context.ConsoleProps.shipNickname}</span> they can:</p>
                <p>- Press A to Assemble 2 held objects together</p>
                <p>- Press D to Disassemble them</p>
                <p>Press GO when ready.</p>
            </div>
        </>;
    }
}
P5B2.contextType = MissionConsoleContext;

class P5C extends React.Component {

    componentDidMount() {
        this.context.ConsoleProps.doEmit('cbUpdateState', { landingBtn:true }); // enable landing button

        this.context.ConsoleProps.addListener('p5c-landingClear', 'landingBtnClick', () => {
            this.context.ConsoleProps.doEmit('cbUpdateState', { landingBtn:false }); // disable landing button

            this.context.ConsoleProps.socket.emit("message", {
                type: "communicateToShip",
                content: {
                    code: "landing"
                }
            }, (response) => {
                if(response.status === "ok")
                {
                    //
                }
                // TODO: do proper error-checking?
            });
        });

        this.context.ConsoleProps.addListener('p5c-landingInitiated', 'landing-initiated', () => {
            this.props.changeFrame('6A');
        });
    }

    componentWillUnmount() {
        this.context.ConsoleProps.doEmit('cbUpdateState', { landingBtn:false });
        this.context.ConsoleProps.delListener('p5c-landingClear');
        this.context.ConsoleProps.delListener('p5c-landingInitiated');
    }

    render() {
        return <>
            <div style={this.props.style.text}>
                <h1>CAPCOM</h1>
                <p>Ready to begin computer-guided descent to lunar surface.</p>
                <p>- Press LANDING CLEAR to unlock automated landing for <span>{this.context.ConsoleProps.shipNickname}</span>.</p>
                <p>- <span>{this.context.ConsoleProps.shipNickname}</span> can press the YELLOW BUTTON at the bottom right of their control panel to initiate landing sequence.</p>
            </div>
        </>;
    }
}
P5C.contextType = MissionConsoleContext;

class P6A extends React.Component {

    componentDidMount() {
        this.context.ConsoleProps.doEmit('cbUpdateState', { goBtn:true });
        this.context.ConsoleProps.addListener('p6a-go', 'goBtnClick', () => this.props.changeFrame('6B'));

        this.context.ConsoleProps.socket.emit("message", {
            type: "stateUpdate",
            content: {
                name: this.context.ConsoleProps.consoleName,
                state: "in-game"
            }
        }, (response) => {
            // TODO: do proper error-checking?
        });
    }

    componentWillUnmount() {
        this.context.ConsoleProps.doEmit('cbUpdateState', { goBtn:false });
        this.context.ConsoleProps.delListener('p6a-go');
    }

    render() {
        return <>
            <div style={this.props.style.text}>
                <h1>CAPCOM - Routine Processes</h1>
                <p>- Guided Landing Initiated...</p>
                <p>Tell <span>{this.context.ConsoleProps.shipNickname}</span> to stir their oxygen tanks by pressing the RED button at the bottom left of their control panel.</p>
                <p>Press GO on completion.</p>
            </div>
        </>;
    }
}
P6A.contextType = MissionConsoleContext;

function P6B(props) {

    const styleSheet = {
        table: {
            minWidth: "80%",
            paddingLeft: "2em"
        },
    }
    
    const [landingTime, setLandingTime] = useState(-1);
    const value = useContext(MissionConsoleContext);
    const latestConsoleProps = useRef(value.ConsoleProps);
    
    const { ConsoleProps } = value;

    useEffect(() => {
        latestConsoleProps.current = value.ConsoleProps;
    });

    useEffect(() => {
        function tick() {
            // update relevant values from latestConsoleProps tick
            setLandingTime(latestConsoleProps.current.lemStatus.landingTimerValue);
        }
        const id = setInterval(tick, 1000);
        return () => clearInterval(id);
    }, []); // This effect never re-runs

    useEffect(() => {
        ConsoleProps.doEmit('cbUpdateState', { statusBtn:true, panicBtn: true });

        ConsoleProps.addListener('p6b-status', 'statusBtnClick', () => props.changeFrame('7A'));
        ConsoleProps.addListener('p6b-panic', 'panicBtnClick', () => props.changeFrame('8A'));

        return () => {
            ConsoleProps.doEmit('cbUpdateState', { statusBtn:false, panicBtn: false });
            
            ConsoleProps.delListener('p6b-status');
            ConsoleProps.delListener('p6b-panic');
        }

    }, [ConsoleProps, props]); // This effect never re-runs

    return (<>
        <div style={props.style.text}>
            <h1>CAPCOM - Guided Landing Sequence Initiated</h1>
            <table style={styleSheet.table}>
                <tbody>
                <tr>
                    <td>Estimated Landing Time</td>
                    <td id={"landing-time"}>{Utils.toMMSS(landingTime)}</td>
                </tr>
                <tr>
                    <td>On Schedule</td>
                    <td>Yes</td>
                </tr>
                </tbody>
            </table>
        </div>
    </>);
}

// [PAGE] STATUS - OVERVIEW
function P7A(props) {

    const styleSheet = {
        table: {
            minWidth: "80%",
            paddingLeft: "2em"
        },
    }

    const [lunarAltitude, setLunarAltitude] = useState(0);
    const [temp, setTemp] = useState(0);
    const [oxygen, setOxygen] = useState(0);
    const [co2, setCO2] = useState(0);
    const [fuelLevel, setFuelLevel] = useState(0);

    const value = useContext(MissionConsoleContext);
    const latestConsoleProps = useRef(value.ConsoleProps);

    const { ConsoleProps } = value;

    useEffect(() => {
        latestConsoleProps.current = value.ConsoleProps;
    });

    useEffect(() => {
        function tick() {
            // update relevant values from latestConsoleProps tick
            setLunarAltitude(latestConsoleProps.current.lemStatus.altitudeValue);
            setTemp(latestConsoleProps.current.lemStatus.interiorTemp);
            setOxygen(latestConsoleProps.current.lemStatus.oxygenLevel);
            setCO2(latestConsoleProps.current.lemStatus.co2Level);
            setFuelLevel(latestConsoleProps.current.lemStatus.mainFuel);
        }
        const id = setInterval(tick, 100);
        return () => clearInterval(id);
    }, []); // This effect never re-runs

    useEffect(() => {
        ConsoleProps.doEmit('cbUpdateState', { capcomBtn:true, panicBtn: true });

        ConsoleProps.addListener('p7a-capcom', 'capcomBtnClick', () => props.changeFrame('6B'));
        ConsoleProps.addListener('p7a-panic', 'panicBtnClick', () => props.changeFrame('8A'));

        return () => {
            ConsoleProps.doEmit('cbUpdateState', { capcomBtn:false, panicBtn: false });

            ConsoleProps.delListener('p7a-capcom');
            ConsoleProps.delListener('p7a-panic');
        }

    }, [ConsoleProps, props]); // This effect never re-runs

    const table = () => {
        if(lunarAltitude == null)
            return (
                <table style={styleSheet.table}>
                    <tbody>
                    <tr>
                        <td>Lunar Altitude</td>
                        <td id={"lem-alt"}>Waiting...</td>
                    </tr>
                    <tr>
                        <td>Interior Temp</td>
                        <td id={"lem-temp"}>Waiting...</td>
                    </tr>
                    <tr>
                        <td>Oxygen Level</td>
                        <td id={"lem-oxygen"}>Waiting...</td>
                    </tr>
                    <tr>
                        <td>CO2 Level</td>
                        <td id={"lem-co2"}>Waiting...</td>
                    </tr>
                    <tr>
                        <td>Main Fuel Level</td>
                        <td id={"lem-fuel"}>Waiting...</td>
                    </tr>
                    </tbody>
                </table>
            );
        else
            return (
                <table style={styleSheet.table}>
                    <tbody>
                    <tr>
                        <td>Lunar Altitude</td>
                        <td id={"lem-alt"}>{lunarAltitude.toFixed(2)} ft</td>
                    </tr>
                    <tr>
                        <td>Interior Temp</td>
                        <td id={"lem-temp"}>{temp}°</td>
                    </tr>
                    <tr>
                        <td>Oxygen Level</td>
                        <td id={"lem-oxygen"}>{oxygen.toFixed(2)}%</td>
                    </tr>
                    <tr>
                        <td>CO2 Level</td>
                        <td id={"lem-co2"}>{co2.toFixed(2)}%</td>
                    </tr>
                    <tr>
                        <td>Main Fuel Level</td>
                        <td id={"lem-fuel"}>{fuelLevel.toFixed(2)}%</td>
                    </tr>
                    </tbody>
                </table>
            );
    }

    return (<>
        <div style={props.style.text}>
            <h1>STATUS - Overview</h1>
            {table()}
        </div>
    </>);
}

function P8A(props) {
    const value = useContext(MissionConsoleContext);
    const { ConsoleProps } = value;

    useEffect(() => {
        ConsoleProps.doEmit('cbUpdateState', { capcomBtn:true, statusBtn:true });

        ConsoleProps.addListener('p8a-capcom', 'capcomBtnClick', () => props.changeFrame('6B'));
        ConsoleProps.addListener('p8a-status', 'statusBtnClick', () => props.changeFrame('7A'));

        return () => {
            ConsoleProps.doEmit('cbUpdateState', { capcomBtn:false, statusBtn:false });

            ConsoleProps.delListener('p8a-capcom');
            ConsoleProps.delListener('p8a-status');
        }

    }, [ConsoleProps, props]); // This effect never re-runs

    return (<>
        <div style={props.style.text}>
            <h1>PANIC - What To Do</h1>
            <p>Use documents with astronaut to identify:</p>
            <p>&nbsp;&nbsp;- What component is malfunctioning</p>
            <p>&nbsp;&nbsp;- How to construct a replacement</p>
            <p>&nbsp;&nbsp;- It is vital to the astronaut's survival that they do NOT panic</p>
        </div>
    </>);
}

function P9A(props) {
    const value = useContext(MissionConsoleContext);
    const { ConsoleProps } = value;

    useEffect(() => {
        ConsoleProps.socket.emit("message", {
            type: "stateUpdate",
            content: {
                name: ConsoleProps.consoleName,
                state: "in-game"
            }
        }, (response) => {
            // TODO: do proper error-checking?
        });

        ConsoleProps.addListener('p9a-lemLinked', 'lemLinked', () => {
            props.changeFrame('7A');
        });

        ConsoleProps.addListener('p9a-lemUpdate', 'lemUpdate', () => {
            props.changeFrame('7A');
        });

        return () => {
            ConsoleProps.delListener('p9a-lemLinked');
            ConsoleProps.delListener('p9a-lemUpdate');
        }

    }, [ConsoleProps, props]); // This effect never re-runs

    return (<>
        <div style={props.style.text}>
            <h1>SYS ENGINEER - Waiting</h1>
            <p>Waiting on CAPCOM to link with the lunar module...</p>
        </div>
    </>);
}



export default class Screen extends React.Component {
    constructor(props) {
        super(props);
        this.history = new Stack(5);

        this.state = { frameName: 'StartScreen', frameArg: null};
        this.history.push({frameName: 'StartScreen', frameArg: null });

        this.changeFrame = this.changeFrameName.bind(this);
        this.goBack = this.goBackInHistory.bind(this);
    }

    componentDidMount() {
        this.context.ConsoleProps.addListener('screen-onChangeFrameEvent', 'screen-onChangeFrame', (newFrame, args) => {
            this.changeFrame(newFrame, args);
        });

        this.context.ConsoleProps.addListener('screen-onGoBackEvent', 'screen-onGoBack', () => {
            this.goBackInHistory();
        });

        this.context.ConsoleProps.addListener('screen-onRestoreEvent', 'screen-onRestore', (historyArray) => {
            this.restoreHistory(historyArray);
        });
    }

    componentWillUnmount() {
        this.context.ConsoleProps.delListener('screen-onChangeFrameEvent');
        this.context.ConsoleProps.delListener('screen-onGoBackEvent');
        this.context.ConsoleProps.delListener('screen-onRestoreEvent');
    }

    onClickScreen() {
        this.context.ConsoleProps.doEmit('screenClick');
    }

    changeFrameName(frameName, frameArg) {
        const lastHistoryItem = this.history.peek();

        if(lastHistoryItem == null || lastHistoryItem.frameName !== frameName) {
            this.history.push({
                frameName: frameName,
                frameArg: frameArg,
            });

            this.context.ConsoleProps.socket.emit("message", {
                type: "stateUpdate",
                content: {
                    name: this.context.ConsoleProps.consoleName,
                    screenHistory: this.history.toArray(),
                }
            }, (response) => {
                // TODO: do proper error-checking?
            });
        }

        const newState = {
            frameName: frameName,
            frameArg: frameArg,
        };

        this.setState(newState);
    }

    goBackInHistory() {
        if(this.history.willBeEmptyAfterPop()) {
            this.history.pop();
        }
        else if(!this.history.isEmpty()) {
            this.history.pop();
            const lastHistoryItem = this.history.peek();

            this.setState({
                frameName: lastHistoryItem.frameName,
                frameArg: lastHistoryItem.frameArg,
            });
        }
    }

    restoreHistory(historyArray) {
        this.history.setContainer(historyArray);
        if(!this.history.isEmpty()) {
            const lastHistoryItem = this.history.peek();
            this.setState({
                frameName: lastHistoryItem.frameName,
                frameArg: lastHistoryItem.frameArg,
            });
        }
    }

    Frame(props) {
        const consoleFrames = new Map(Object.entries({
            'StartScreen': <StartScreen {...props} />,
            '5A': <P5A {...props} />,
            '5B': <P5B {...props} />,
            '5B2': <P5B2 {...props} />,
            '5C': <P5C {...props} />,
            '6A': <P6A {...props} />,
            '6B': <P6B {...props} />,
            '7A': <P7A {...props} />,
            '8A': <P8A {...props} />,
            '9A': <P9A {...props} />,
        }));

        if(consoleFrames.has(props.frameName) === false)
            return <></>;

        return <> {consoleFrames.get(props.frameName)} </>;
    }

    render() {
        return(
            <div className={"screen"} style={this.props.style.screen} onClick={() => this.onClickScreen()}>
                <this.Frame frameName={this.state.frameName} frameArg={this.state.frameArg} style={this.props.style}
                            changeFrame={this.changeFrame} goBack={this.goBack}/>
            </div>
        )
    }
}

Screen.contextType = MissionConsoleContext;