import React, { useEffect, useRef, useState } from "react";
import apolloDocs from '../../resources/apollo_docs.pdf';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import {MissionConsoleContext} from "../../contexts/MissionConsoleContext";
import Stack from "../../utils/Stack";
import RadioBeep from "../../resources/nasaBeep.mp3";

const fnStatuses = new Set();

function P0(props) {
    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "inherit", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)"
        },
        topHeader: {
            ...props.style.body,
            marginBottom: "0.2rem",
        },
        bottomHeader: {
            ...props.style.body,
            marginTop: "0.2rem",
        }
    }

    return <>
        <h1 style={props.style.header}>Welcome</h1>
        <p style={styleSheet.topHeader}>How many players will be in Mission Command?</p>
        <p style={styleSheet.bottomHeader}>(Not counting the astronaut)</p>
        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <button className={"btn-outline-light"} onClick={() => props.changeFrame('1A')} style={styleSheet.button}>1</button>
            <button className={"btn-outline-light"} onClick={() => props.changeFrame('2A', { groupSize: 2 })} style={styleSheet.button}>2</button>
            <button className={"btn-outline-light"} onClick={() => props.changeFrame('2A', { groupSize: 3 })} style={styleSheet.button}>3</button>
            <button className={"btn-outline-light"} onClick={() => props.changeFrame('2A', { groupSize: 4 })} style={styleSheet.button}>4</button>
        </div>
    </>;
}

function P1A(props) {
    useEffect(() => {
        function emitNewRole() {
            props.consoleProps.socket.emit("message", {
                type: "stateUpdate",
                content: {
                    name: props.consoleProps.consoleName,
                    state: "instructions"
                }
            }, (response) => {
                // TODO: do proper error-checking?
            });
        }
        emitNewRole();
    }); // run this only once

    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "inherit", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)"
        },
        playerCount: {
            width: "calc(80vw/20)",
            margin: "0 auto",
            height: "calc(80vw/20)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "calc(80vw/36)",
            fontFamily: "Futuracrunch"
        }
    }

    return <>
        <div className={"underline-light"} style={styleSheet.playerCount}>
            <span>1</span>
        </div>
        <p style={props.style.body}>Do you want to print your documents or have them digitally?</p>
        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <button className={"btn-outline-light"} style={styleSheet.button} onClick={() => props.changeFrame('1B')}>Print</button>
            <button className={"btn-outline-light"} style={styleSheet.button} onClick={() => props.changeFrame('1C')}>Digital</button>
        </div>
    </>;
}

function P1B(props) {
    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "inherit", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)"
        },
        playerCount: {
            width: "calc(80vw/20)",
            margin: "0 auto",
            height: "calc(80vw/20)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "calc(80vw/36)",
            fontFamily: "Futuracrunch"
        }
    }

    return <>
        <div className={"underline-light"} style={styleSheet.playerCount}>
            <span>1</span>
        </div>
        <p style={props.style.body}>Click to open the documents in a new tab. Print them, then return here for access to the Mission Control console.</p>
        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <button className={"btn-outline-light"} style={styleSheet.button} onClick={() => {
                window.open(apolloDocs, "_blank");
                props.changeFrame('2NS');
            }}>Print Docs</button>
        </div>
    </>;
}

function P1C(props) {
    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "inherit", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)"
        },
        playerCount: {
            width: "calc(80vw/20)",
            margin: "0 auto",
            height: "calc(80vw/20)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "calc(80vw/36)",
            fontFamily: "Futuracrunch"
        }
    }

    useEffect(() => {
        props.consoleProps.showGallery();
    });

    return <>
        <div className={"underline-light"} style={styleSheet.playerCount}>
            <FontAwesomeIcon icon={faArrowUp} />
        </div>
        <p style={props.style.body}>Your docs will be up here. Click one to make it fullscreen.</p>
        <p style={props.style.body}>Take some time now to look through them all.</p>
        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <button className={"btn-outline-light"} style={styleSheet.button} onClick={() => props.changeFrame('2NS')}>Continue</button>
        </div>
    </>;
}

function P2A(props) {
    const { groupSize } = props.frameArg;

    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "inherit", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)"
        },
        playerCount: {
            width: "calc(80vw/20)",
            margin: "0 auto",
            height: "calc(80vw/20)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "calc(80vw/36)",
            fontFamily: "Futuracrunch"
        }
    }

    let isPaired = false;

    const onInputChange = (e) => {
        let { value } = e.target;
        const reg = /[^a-zA-Z0-9_-]/g;

        value = value.toUpperCase();
        value = value.replace(reg, "");

        if(value.length >= 4) {
            value = value.substring(0, 4);

            if(isPaired === false)
            {
                props.consoleProps.socket.emit("message", {
                    type: "groupPairing",
                    content: {
                        groupSize: groupSize,
                        codeSubmission: value
                    }
                }, (response) => {
                    if(response.status === "ok") {
                        isPaired = true;
                        document.querySelector("#code-text-input").disabled = true;

                        if(response.content.isGroupComplete === true) {
                            document.querySelector("#p2a-error").textContent = "Successfully paired! Redirecting...";
                            setTimeout(() => {
                                props.changeFrame('2B', { groupSize: groupSize });
                            }, 3000);
                        }
                        else {
                            document.querySelector("#p2a-error").textContent = "Successfully paired! Waiting on others...";
                        }
                    }
                    else {
                        document.querySelector("#code-input").setAttribute("style", "border: 3px solid red");
                        document.querySelector("#p2a-error").textContent = response.error;
                        setTimeout(() => {
                            document.querySelector("#code-input").removeAttribute("style");
                        }, 1000);
                    }
                });
            }
        }

        e.target.value = value;
    };

    const pairingMadeListener = (response) => {
        document.querySelector("#code-text-input").disabled = true;
        isPaired = true;

        if(response.isGroupComplete) {
            document.querySelector("#p2a-error").textContent = "Successfully paired! Redirecting...";

            setTimeout(() => {
                props.changeFrame('2B', { groupSize: groupSize });
            }, 1500);
        } else {
            document.querySelector("#p2a-error").textContent = "Successfully paired! Waiting on others...";
            document.querySelector("#code-input").setAttribute("style", "display:none");
        }
    };

    useEffect(() => {
        props.consoleProps.socket.on("pairing-made", pairingMadeListener);

        return () => {
            props.consoleProps.socket.off("pairing-made", pairingMadeListener);
        }
    });

    useEffect(() => {
        props.consoleProps.socket.emit("message", {
            type: "stateUpdate",
            content: {
                name: props.consoleProps.consoleName,
                type: "MissionConsole",
                state: "pairing"
            }
        }, (response) => {
        });
    }, []);

    return <>
        <div className={"underline-light"} style={styleSheet.playerCount}>
            <span>{groupSize}</span>
        </div>
        <p style={props.style.body}>Input this code into your fellow Mission Commanders' box to connect: <span style={{color: "#d8492e"}}>{props.consoleProps.playerCode}</span></p>

        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <div className={"outline-light"} id={"code-input"} style={styleSheet.inputWrap}>
                <input id={"code-text-input"} type="text" onInput={(e) => onInputChange(e)} />
            </div>
        </div>
        <p style={{fontFamily: 'Futuracrunch'}} id={"p2a-error"}/>
        <p>Only one of you is required to enter a code.</p>
    </>;
}

function P2B(props) {
    const { groupSize } = props.frameArg;

    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "inherit", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)"
        },
        playerCount: {
            width: "calc(80vw/20)",
            margin: "0 auto calc(80vw/50) auto",
            height: "calc(80vw/20)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "calc(80vw/36)",
            fontFamily: "Futuracrunch"
        }
    }

    if(fnStatuses.has("2b-starter") === false)
    {
        props.consoleProps.socket.emit("message", {
            type: "stateUpdate",
            content: {
                name: props.consoleProps.consoleName,
                type: "MissionConsole",
                state: "choosing-role"
            }
        }, (response) => {
        });

        props.consoleProps.socket.on("group-update", (response) => {
            const groupStates = new Map(response.groupStates);

            let capcom_count = 0;
            let engineer_count = 0;
            let my_role = null;

            groupStates.forEach((groupState) => {
                if (groupState.name.trim() === props.consoleProps.consoleName.trim()) {
                    my_role = groupState.role;
                }
                if (groupState.role === "capcom") capcom_count += 1;
                else if (groupState.role === "engineer") engineer_count += 1;
            });

            let capcom_btn = document.querySelector("#capcom-btn");
            let engineer_btn = document.querySelector("#engineer-btn");

            engineer_btn.disabled = ((my_role !== "engineer" && engineer_count > groupSize - 2) || my_role === "capcom");
            engineer_btn.dataset.chosen = (my_role !== "engineer" && engineer_count > groupSize - 2) ? "true" : "false";
            engineer_btn.dataset.selected = (my_role === "engineer") ? "true" : "false";

            capcom_btn.disabled = ((my_role !== "capcom" && capcom_count > 0) || my_role === "engineer");
            capcom_btn.dataset.chosen = (my_role !== "capcom" && capcom_count > 0) ? "true" : "false";
            capcom_btn.dataset.selected = (my_role === "capcom") ? "true" : "false";

            if (capcom_count + engineer_count === groupStates.size)
            {
                props.consoleProps.consoleRole = my_role;

                if (my_role === "capcom") {
                    props.changeFrame('2Docs');
                } else if (my_role === "engineer") {
                    props.changeFrame('2E');
                }
            }
        });

        fnStatuses.add("2b-starter");
    }

    const sendRole = (role) => {
        let selectedButton = null;
        let otherButton = null;
        if (role === "capcom") {
            selectedButton = document.querySelector("#capcom-btn");
            otherButton = document.querySelector("#engineer-btn");
        } else if (role === "engineer") {
            selectedButton = document.querySelector("#engineer-btn");
            otherButton = document.querySelector("#capcom-btn");
        }

        if(selectedButton == null || otherButton == null) {
            console.error("Popup.js: selectedButton or otherButton is null");
            return;
        }

        if(selectedButton.dataset.selected === "false") {
            selectedButton.dataset.selected = "true";

            otherButton.disabled = true;

            props.consoleProps.socket.emit("message", {
                type: "sendRole",
                content: {
                    type: "MissionConsole",
                    toggleState: "on",
                    role: role
                }
            }, (response) => {
                if(response.status === "ok") {
                    // TODO: do proper error-checking?
                }
            });
        }
        else {
            selectedButton.dataset.selected = "false";

            props.consoleProps.socket.emit("message", {
                type: "sendRole",
                content: {
                    type: "MissionConsole",
                    toggleState: "off",
                    role: role
                }
            }, (response) => {
                if(response.status === "ok") {
                    // TODO: Do proper error-checking?
                }
            });

            if(otherButton.dataset.chosen === "false")
                otherButton.disabled = false;
        }
    }

    return <>
        <div className={"underline-light"} style={styleSheet.playerCount}>
            <span>{groupSize}</span>
        </div>
        <h1 style={props.style.header}>Choose a Role</h1>
        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <button id={"capcom-btn"} data-selected={"false"} data-chosen={"false"} className={"btn-outline-light"} style={styleSheet.button} onClick={() => sendRole("capcom")}>Capcom</button>
            <button id={"engineer-btn"} data-selected={"false"} data-chosen={"false"} className={"btn-outline-light"} style={styleSheet.button} onClick={() => sendRole("engineer")}>Systems Engineer</button>
        </div>
    </>;
}

function P2NameShip(props) {

    useEffect(() => {
        if(fnStatuses.has("p2nameship-starter") === false) {
            props.consoleProps.socket.emit("message", {
                type: "stateUpdate",
                content: {
                    name: props.consoleProps.consoleName,
                    state: "naming-ship"
                }
            }, (response) => {
            });

            fnStatuses.add("p2nameship-starter");
        }
    }, []); // run this only once on mount

    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "FuturacrunchCond", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)",
            marginTop: "2rem",
        }
    }

    const onInputChange = (e) => {
        let { value } = e.target;

        value = value.toUpperCase();

        if(value.length >= 20) {
            value = value.substring(0, 20);
        }

        e.target.value = value;
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const spacecraft_name = e.target.elements["code-text-input"].value;
        if(spacecraft_name.length === 0) return;

        props.consoleProps.shipNickname = spacecraft_name;
        props.changeFrame('2C');
    };

    return <>
        <p style={props.style.body}>Give Your Lunar Spacecraft a Name:</p>

        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <form onSubmit={(e) => onSubmit(e)}>
                <div className={"underline-light"} id={"code-input"} style={styleSheet.inputWrap}>
                    <input id={"code-text-input"} type="text" onInput={(e) => onInputChange(e)} />
                </div>
                <button id={"submit-name-btn"} className={"btn-outline-light"} style={styleSheet.button}>Submit</button>
            </form>
        </div>
    </>;
}

function P2C(props) {
    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "inherit", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)"
        }
    }

    if(fnStatuses.has("2c-starter") === false)
    {
        props.consoleProps.socket.emit("message", {
            type: "stateUpdate",
            content: {
                name: props.consoleProps.consoleName,
                role: "capcom",
                state: "instructions"
            }
        }, (response) => {
            // TODO: do proper error-checking?
        });

        fnStatuses.add("2c-starter");
    }

    return <>
        <h1 style={props.style.header}>CAPCOM</h1>
        <ul>
            <li style={props.style.body}>You are the capcom (capsule communicator)</li>
            <li style={props.style.body}>&nbsp;&nbsp;Only you should talk to the astronaut.</li>
            <li style={props.style.body}>No one in Mission Command is allowed to look at the astronaut's screen.</li>
        </ul>
        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <button className={"btn-outline-light"} style={styleSheet.button} onClick={() => props.changeFrame('2D')}>Next</button>
        </div>
    </>;
}

function P2Docs(props) {
    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "inherit", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)"
        },
        playerCount: {
            width: "calc(80vw/20)",
            margin: "0 auto",
            height: "calc(80vw/20)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "calc(80vw/36)",
            fontFamily: "Futuracrunch"
        }
    }

    return <>
        <h1 style={props.style.header}>CAPCOM</h1>
        <p style={props.style.body}>Do you want to print your documents or have them digitally?</p>
        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <button className={"btn-outline-light"} style={styleSheet.button} disabled={false} onClick={() => props.changeFrame('2PrintDocs')}>Print</button>
            <button className={"btn-outline-light"} style={styleSheet.button} disabled={false} onClick={() => props.changeFrame('1C')}>Digital</button>
        </div>
    </>;
}

function P2PrintDocs(props) {
    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "inherit", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)"
        },
        playerCount: {
            width: "calc(80vw/20)",
            margin: "0 auto",
            height: "calc(80vw/20)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "calc(80vw/36)",
            fontFamily: "Futuracrunch"
        }
    }

    return <>
        <p style={props.style.body}>Click to open the documents in a new tab. Print them, then return here for access to the Mission Control console.</p>
        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <button className={"btn-outline-light"} style={styleSheet.button} onClick={() => {
                window.open(apolloDocs, "_blank");
                props.changeFrame('2NS');
            }}>Print Docs</button>
        </div>
    </>;
}

function P2D(props) {
    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "inherit", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)"
        },
        header: { ...props.style.header }
    }

    Object.assign(styleSheet.header, {
        marginBottom: "0.1rem",
    })

    return <>
        <h1 style={styleSheet.header}>CAPCOM</h1>
        <ul>
            <li style={props.style.body}>The astronaut is in the Lunar Lander, currently orbiting the moon. They are almost ready to begin landing.</li>
            <li style={props.style.body}>For dramatic effect, players can press and hold the Enter key when they talk.</li>
        </ul>
        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <button className={"btn-outline-light"} style={styleSheet.button} onClick={() => {
                props.consoleProps.doEmit('screen-onChangeFrame', '5A');
                props.close();
            }}>Go</button>
        </div>
    </>;
}

function P2E(props) {
    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "inherit", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)"
        },
        playerCount: {
            width: "calc(80vw/20)",
            margin: "0 auto",
            height: "calc(80vw/20)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "calc(80vw/36)",
            fontFamily: "Futuracrunch"
        }
    }

    return <>
        <h1 style={props.style.header}>SYS ENGINEER</h1>
        <p style={props.style.body}>Do you want to print your documents or have them digitally?</p>
        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <button className={"btn-outline-light"} style={styleSheet.button} disabled={false} onClick={() => props.changeFrame('2F')}>Print</button>
            <button className={"btn-outline-light"} style={styleSheet.button} disabled={false} onClick={() => props.changeFrame('2H')}>Digital</button>
        </div>
    </>;
}

function P2F(props) {
    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "inherit", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)"
        },
        playerCount: {
            width: "calc(80vw/20)",
            margin: "0 auto",
            height: "calc(80vw/20)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "calc(80vw/36)",
            fontFamily: "Futuracrunch"
        }
    }

    return <>
        <p style={props.style.body}>Click to open the documents in a new tab. Print them, then return here for access to the Mission Control console.</p>
        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <button className={"btn-outline-light"} style={styleSheet.button} onClick={() => {
                window.open(apolloDocs, "_blank");
                props.changeFrame('2G');
            }}>Print Docs</button>
        </div>
    </>;
}

function P2G(props) {
    useEffect(() => {
        props.consoleProps.socket.emit("message", {
            type: "stateUpdate",
            content: {
                name: props.consoleProps.consoleName,
                state: "instructions"
            }
        }, (response) => {
            // TODO: do proper error-checking?
        });
    }, [props.consoleProps.consoleName, props.consoleProps.socket]); // run this only once

    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "inherit", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)"
        },
        header: {
            fontFamily: 'Futuracrunch',
            margin: "0 0 0 0",
            fontSize: "calc(80vw/18)",
            textShadow: "0px 2px 8px rgba(0, 0, 0, 0.8)",
        },
        body: {
            fontFamily: 'FuturacrunchCond',
            fontSize: "calc(80vw/34)",
            margin: "calc(80vw/130) 0 calc(80vw/130) 0",
        }
    }

    return <>
        <h1 style={styleSheet.header}>SYS ENGINEER</h1>
        <ul>
            <li style={styleSheet.body}>System engineers should not talk to the astronaut and cannot look at the astronaut's screen.</li>
            <li style={styleSheet.body}>Use your documents to solve problems as they happen.</li>
        </ul>
        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <button className={"btn-outline-light"} style={styleSheet.button} onClick={() => {
                props.consoleProps.doEmit('screen-onChangeFrame', '9A');
                props.close();
            }}>Go</button>
        </div>
    </>;
}

function P2H(props) {
    const styleSheet = {
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "inherit", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)"
        },
        playerCount: {
            width: "calc(80vw/20)",
            margin: "0 auto",
            height: "calc(80vw/20)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "calc(80vw/36)",
            fontFamily: "Futuracrunch"
        }
    }

    useEffect(() => {
        props.consoleProps.showGallery();
    });

    return <>
        <div className={"underline-light"} style={styleSheet.playerCount}>
            <FontAwesomeIcon icon={faArrowUp} />
        </div>
        <p style={props.style.body}>Your docs will be up here. Click one to make it fullscreen.</p>
        <p style={props.style.body}>Take some time now to look through them all.</p>
        <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
            <button className={"btn-outline-light"} style={styleSheet.button} onClick={() => props.changeFrame('2G')}>Continue</button>
        </div>
    </>;
}

function PauseScreen(props) {
    const styleSheet = {
        header: {
            fontFamily: 'Futuracrunch',
            margin: "0 0 0 0",
            fontSize: "calc(80vw/18)",
            textShadow: "0px 2px 8px rgba(0, 0, 0, 0.8)",
        }
    }

    return <>
        <h1 style={styleSheet.header}>Astronaut Paused the Game</h1>
    </>;
}

function GroupChange(props) {
    const { frameArg, consoleProps, goBack, forceClose } = props;
    const [didAskBypass, setDidAskBypass] = useState(consoleProps.missionState.didAskToBypass);
    const subframe = useRef(<></>);
    const { didAskToBypass, isBypassingEngineers, isMissingLEM, isMissingCapcom } = props.consoleProps.missionState;

    const isMissingLEMorCapcom = isMissingLEM || isMissingCapcom;
    const isSafeToCloseForEngineer = frameArg.roleType === "engineer" && didAskToBypass && isBypassingEngineers && !isMissingLEMorCapcom;
    const isSafeToCloseForNone = frameArg.roleType === "none" && frameArg.changeType === "connected" && !isMissingLEMorCapcom;
    const thisClientConnected = frameArg.deltaActive.find(memberName => memberName === consoleProps.consoleName) != null;

    useEffect(() => {
        if(isSafeToCloseForEngineer || isSafeToCloseForNone)
        {
            goBack();
        }
        else if(thisClientConnected) {
            forceClose();
        }

    }, [didAskToBypass, isBypassingEngineers, isMissingLEM,
        isMissingCapcom, isSafeToCloseForEngineer, isSafeToCloseForNone,
        thisClientConnected, goBack]
    );

    const styleSheet = {
        header: {
            fontFamily: 'Futuracrunch',
            margin: "0 0 0 0",
            fontSize: "calc(80vw/18)",
            textShadow: "0px 2px 8px rgba(0, 0, 0, 0.8)",
        },
        button: {
            fontSize: "calc(80vw/40)", fontFamily: "Futuracrunch", minWidth: "calc(80vw/19)", minHeight: "calc(80vw/20)", padding: "0 calc(80vw/50)"
        },
    }

    const EngineerComponent = () => {
        return(
            <>
                <h1 style={styleSheet.header}>A fellow engineer has disconnected.</h1>
                <EngineerDecisionButtons/>
            </>
        );
    }

    const CapcomComponent = () => {
        return(
            <>
                <h1 style={styleSheet.header}>Your capcom has disconnected.</h1>
                <p style={props.style.body}>The experience will now pause until their return.</p>
                <p style={props.style.body}>Waiting on them to reconnect...</p>
            </>
        );
    }

    const LEMComponent = () => {
        return(
            <>
                <h1 style={styleSheet.header}>Lost communication with the ship.</h1>
                <p style={props.style.body}>Unfortunately, you will need to restart your play session.</p>
            </>
        );
    }

    const EngineerDecisionButtons = () => {

        if(didAskBypass && !isBypassingEngineers) {
            return (
                <>
                    <p style={props.style.body}>Waiting on them to reconnect...</p>
                    <button id={"changed-mind-btn"} className={"btn-outline-light"} style={styleSheet.button}
                            onClick={() => {
                                setDidAskBypass(true);
                                props.consoleProps.missionState.isBypassingEngineers = true;
                                goBack();
                            }}>Continue without them
                    </button>
                </>
            );
        }
        return (
            <>
                <p style={props.style.body}>Do you want to continue on without them?</p>
                <div style={{display: "flex", justifyContent: "space-evenly", fontFamily: 'Futuracrunch'}}>
                    <button id={"yes-bypass-btn"} className={"btn-outline-light"} style={styleSheet.button}
                            onClick={() => {
                                setDidAskBypass(true);
                                props.consoleProps.missionState.didAskToBypass = true;
                                props.consoleProps.missionState.isBypassingEngineers = true;
                                goBack();
                            }}>Yes
                    </button>
                    <button id={"no-bypass-btn"} className={"btn-outline-light"} style={styleSheet.button}
                            onClick={() => {
                                setDidAskBypass(true);
                                props.consoleProps.missionState.didAskToBypass = true;
                                props.consoleProps.missionState.isBypassingEngineers = false;
                            }}>No
                    </button>
                </div>
            </>
        );
    }


    if(props.frameArg.changeType === "disconnected") {
        if(props.frameArg.roleType === "astronaut" || isMissingLEM) {
            subframe.current = <LEMComponent/>;
        }
        else if(props.frameArg.roleType === "capcom" || isMissingCapcom) {
            subframe.current = <CapcomComponent/>;
        }
        else if(props.frameArg.roleType === "engineer" && !isMissingLEMorCapcom)
            subframe.current = <EngineerComponent/>;
    }

    return (<>
        {subframe.current}
    </>);
}

function MissionWin(props) {

    useEffect(() => {
        props.consoleProps.socket.disconnect();

        setTimeout(() => {
            document.addEventListener("keyup", (e) => {
                if(e.code === "Escape" && !e.repeat) {
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                }
            });
        }, 85000);
    });

    const styleSheet = {
        header: {
            fontFamily: 'Futuracrunch',
            margin: "0 0 0 0",
            fontSize: "calc(80vw/12)",
            textShadow: "0px 2px 8px rgba(0, 0, 0, 0.8)",
            lineHeight: "calc(80vw/13)",
        }
    }

    return <>
        <h1 style={styleSheet.header}>MISSION SUCCESS</h1>
        <p style={props.style.body}>Standby for incoming message</p>
    </>;
}

function MissionFail(props) {

    useEffect(() => {
        props.consoleProps.socket.disconnect();

        document.addEventListener("keyup", (e) => {
            if(e.code === "Escape" && !e.repeat) {
                // eslint-disable-next-line no-restricted-globals
                location.reload();
            }
        });
    });

    const styleSheet = {
        header: {
            fontFamily: 'Futuracrunch',
            margin: "0 0 0 0",
            fontSize: "calc(80vw/11)",
            textShadow: "0px 2px 8px rgba(0, 0, 0, 0.8)",
        }
    }

    return <>
        <h1 style={styleSheet.header}>YOU LOSE.</h1>
        <p style={props.style.body}>Press ESC to exit.</p>
    </>;
}

const popupFrames = new Map(Object.entries({
    '0': P0,
    '1A': P1A,
    '1B': P1B,
    '1C': P1C,
    '2A': P2A,
    '2B': P2B,
    '2C': P2C,
    '2Docs': P2Docs,
    '2PrintDocs': P2PrintDocs,
    '2NS': P2NameShip,
    '2D': P2D,
    '2E': P2E,
    '2F': P2F,
    '2G': P2G,
    '2H': P2H,
    'PauseScreen': PauseScreen,
    'GroupChange': GroupChange,
    'MissionWin': MissionWin,
    'MissionFail': MissionFail,
}));

export default class Popup extends React.Component {
    constructor(props) {
        super(props);
        this.history = new Stack(5);
        this.state = { frameName: '0', frameArg: null, isVisible: true };

        this.history.push({frameName: '0', frameArg: null });

        this.changeFrame = this.changeFrameName.bind(this);
        this.open = this.openPopup.bind(this);
        this.close = this.closePopup.bind(this);
        this.goBack = this.goBackInHistory.bind(this);
        this.forceClose = this.forceClosePopup.bind(this);
    }

    componentDidMount() {
        this.context.ConsoleProps.addListener('popup-onChangeFrameEvent', 'popup-onChangeFrame', (newFrame, frameArg = null) => {
            this.changeFrame(newFrame, frameArg);
        });
        this.context.ConsoleProps.addListener('popup-onGoBackEvent', 'popup-onGoBack', () => {
            this.goBackInHistory();
        });
        this.context.ConsoleProps.addListener('popup-onRestoreEvent', 'popup-onRestore', (historyArray) => {
            this.restoreHistory(historyArray);
        });
    }

    componentWillUnmount() {
        this.context.ConsoleProps.delListener('popup-onChangeFrameEvent');
        this.context.ConsoleProps.delListener('popup-onGoBackEvent');
        this.context.ConsoleProps.delListener('popup-onRestoreEvent');
    }

    changeFrameName(frameName, frameArg = null) {
        const lastHistoryItem = this.history.peek();

        if(lastHistoryItem == null || lastHistoryItem.frameName !== frameName) {
            this.history.push({
                frameName: frameName,
                frameArg: frameArg,
            });

            this.context.ConsoleProps.socket.emit("message", {
                type: "stateUpdate",
                content: {
                    name: this.context.ConsoleProps.consoleName,
                    popupHistory: this.history.toArray(),
                }
            }, (response) => {
                // TODO: do proper error-checking?
            });
        }

        const newState = {
            frameName: frameName,
            frameArg: frameArg,
            isVisible: (frameName !== "[CLOSE]"),
        };

        this.setState(newState);
    }

    goBackInHistory() {
        if(this.history.willBeEmptyAfterPop()) {
            this.history.pop();
        }
        else if(!this.history.isEmpty()) {
            this.history.pop();
            const lastHistoryItem = this.history.peek();

            this.setState({
                frameName: lastHistoryItem.frameName,
                frameArg: lastHistoryItem.frameArg,
                isVisible: (lastHistoryItem.frameName !== "[CLOSE]"),
            });
        }
    }

    restoreHistory(historyArray) {
        this.history.setContainer(historyArray);
        if(!this.history.isEmpty()) {
            const lastHistoryItem = this.history.peek();
            this.setState({
                frameName: lastHistoryItem.frameName,
                frameArg: lastHistoryItem.frameArg,
                isVisible: (lastHistoryItem.frameName !== "[CLOSE]"),
            });
        }
    }

    openPopup() {
        this.setState({
            isVisible: true
        });
    }

    closePopup() {
        this.changeFrameName("[CLOSE]");
    }

    forceClosePopup() {
        this.setState({
            isVisible: false,
        });
    }

    Frame(props) {
        if(popupFrames.has(props.frameName) === false)
            return <></>;

        return <>
            {(popupFrames.get(props.frameName))(props)}
        </>;
    }

    render() {
        return(
            <>
                {this.state.isVisible &&
                    <div className={"mc-popup-curtain"} style={this.props.style.curtain}>
                        <div className={"mc-popup"} style={this.props.style.window}>
                            <div className={"mc-popup-windowframe"} style={this.props.style.windowFrame}>
                                <this.Frame frameName={this.state.frameName} frameArg={this.state.frameArg} style={this.props.style}
                                            changeFrame={this.changeFrame} close={this.close} forceClose={this.forceClose} goBack={this.goBack} consoleProps={this.context.ConsoleProps}/>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

Popup.contextType = MissionConsoleContext;