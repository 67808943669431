class Stack {
    constructor(maxSize) { // Set default max size if not provided
        if (isNaN(maxSize)) {
            maxSize = 10;
        }
        this.maxSize = maxSize; // Init an array that'll contain the stack values.
        this.container = [];
    }
    setContainer(value) {
        this.container = value;
    }
    toArray() {
        const clonedContainer = JSON.parse(JSON.stringify(this.container));
        return clonedContainer;
    }
    toString() {
        return JSON.stringify(this.container);
    }
    isEmpty() {
        return this.container.length === 0;
    }
    willBeEmptyAfterPop() {
        return this.container.length === 1;
    }
    isFull() {
        return this.container.length >= this.maxSize;
    }
    push(element) {
        // Check if stack is full
        if (this.isFull()) {
            this.container = this.container.slice(1, this.maxSize);
        }

        this.container.push(element);
    }
    pop() { // Check if empty
        if (this.isEmpty()) {
            return false;
        }

        this.container.pop();
        return true;
    }
    peek() {
        if (this.isEmpty()) {
            return undefined;
        }
        return this.container[this.container.length - 1];
    }
    clear() {
        this.container = [];
    }
}

export default Stack;