const { uuid } = require("uuidv4");

const defer = () => {
    let res, rej;

    let promise = new Promise((resolve, reject) => {
        res = resolve;
        rej = reject;
    });

    promise.resolve = res;
    promise.reject = rej;

    return promise;
}

const getConsoleName = () => {
    const storedID = localStorage.getItem('console-name');
    if (storedID != null) return storedID;

    const generated_id = uuid();
    localStorage.setItem('console-name', generated_id.toString());

    return generated_id.toString();
}

const toMMSS = (time_str) => {
    let sec_num = parseInt(time_str, 10); // don't forget the second param
    let hours   = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return minutes+':'+seconds;
}

module.exports = {
    DataTypes: {
    },
    Utils: {
        defer: defer,
        getConsoleName: getConsoleName,
        toMMSS: toMMSS,
    }
};